@import '~bootstrap/scss/bootstrap-utilities';

.family-card {
  &__img-wrap {
    display: block;
    position: relative;
  }

  &__img {
    display: block;
    max-width: 100%;
    margin-bottom: 30px;
    aspect-ratio: 3/2;
    width: 100%;
    height: 100%;
    object-fit: contain;
    //background: $brand-light-green;
  }

  &__link, &__link:hover, &__link:active {
    text-decoration: none;
    color: inherit;
    text-align: center;
    line-height: 1.1;
  }

  &__title {
    font-family: 'allotrope';
    font-size: 1.2em;
    font-weight: bolder;
    color: $brand-dark-green;
    text-wrap: balance;
    margin-bottom: 0.25em;
  }

  &__stats {
    margin-bottom: 0.25em;
    font-size: 0.9em;
    //font-weight: bold;
  }

  &__desc {
    font-size: 0.8em;
  }

  &--seen {
    .family-card__img-wrap {
      &:after {
        content: 'Seen';
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        padding: 0.5em;
        color: #fff;
        background: $brand-dark-green;
      }
    }

    .family-card__desc strong {
      color: $brand-dark-green;
    }
  }

  &--unseen {
    .family-card__img {
      //filter: saturate(0.1);
      transition: filter 0.5s ease;

      &:hover {
        filter: none;
      }
    }

    .family-card__desc strong, .family-card__title a {
      color: $brand-red;
    }
  }
}