@import url('https://api.mapbox.com/mapbox-gl-js/v2.8.2/mapbox-gl.css');

.map-root {
  position: fixed;
  background: #fff;
  z-index: 0;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.minimap-root {
  position: fixed;
  bottom: calc(50px + 2em);
  right: 2em;
  width: 200px;
  height: 130px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.9);
  transition: opacity 0.5s ease;
  pointer-events: none;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 18px;
    border: 1px solid rgba(30, 30, 30, 0.4);
    background: rgba(180, 180, 29, 0.1);
  }

  &.state-hidden {
    opacity: 0;
  }
}

/*.mapboxgl-canvas, .mapboxgl-marker {
  position: absolute;
}*/

/*.mapboxgl-control-container {
  display: none;
}*/

.map-marker {
  width: 52.5px;
  /*transform: width 0.1s ease;*/
}

.map-marker.state-active {
  z-index: 1;
}

.map-marker__bg {
  fill: #51a488;
  transition: fill 0.25s ease;
}

.map-marker.state-active .map-marker__bg {
  fill: #c03221;
}

.zoom-0 .map-marker, .zoom-1 .map-marker, .zoom-2 .map-marker, .zoom-3 .map-marker {
  width: 20px;
  top: -10px;
}

.zoom-4 .map-marker {
  width: 22.5px;
  top: -11.25px;
}

.zoom-5 .map-marker {
  width: 25px;
  top: -12.5px;
}

.zoom-6 .map-marker {
  width: 27.5px;
  top: -13.75px;
}

.zoom-7 .map-marker {
  width: 30px;
  top: -15px;
}

.zoom-8 .map-marker {
  width: 32.5px;
  top: -16.25px;
}

.zoom-9 .map-marker {
  width: 35px;
  top: -17.5px;
}

.zoom-10 .map-marker {
  width: 37.5px;
  top: -18.75px;
}

.zoom-11 .map-marker {
  width: 40px;
  top: -20px;
}

.zoom-12 .map-marker {
  width: 42.5px;
  top: -21.25px;
}

.zoom-13 .map-marker {
  width: 45px;
  top: -22.5px;
}

.zoom-14 .map-marker {
  width: 47.5px;
  top: -23.75px;
}

.zoom-15 .map-marker {
  width: 50px;
  top: -25px;
}

