@import '~bootstrap/scss/bootstrap-utilities';

.map-mode-control {
  position: absolute;
  display: flex;
  align-items: center;
  top: 67px;
  left: 0;
  z-index: 99;
  background: rgba($brand-gray, 0.8);
  width: 100%;
  padding: 0.66em 30px;

  select {
    margin-right: 1em;
  }

  &__actions {
    margin-left: auto;
  }
}