@import "../assets/scss/bootstrap-utilities";

.observation-card {
  display: flex;
  align-items: flex-start;
  margin: 0.5em;

  & + .observation-card {
    margin-top: 1.5rem;
  }

  &__img {
    display: block;
    width: 130px;
    height: 130px;
    margin-right: 1.5em;
    border-radius: 15px;
    overflow: hidden;
    outline: 5px solid rgba($brand-gray, 0.25);
    flex-shrink: 0;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 10%;
    }
  }

  &__body {
    line-height: 1.1;
    width: 17.5em;
    margin-right: 0.75em;
  }

  &__kicker, &__title {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    margin-bottom: 0.1em;
  }

  &__kicker {
    color: $brand-dark-green;
    font-size: $h5-font-size;
    margin-bottom: 0.25em;
  }

  &__title {
    color: $brand-red;
    font-size: $h3-font-size;

    a {
      color: $brand-red;
    }
  }

  &__copy {
    line-height: 1.3;
    font-size: 0.925em;
  }

  &__icon {
    display: flex;
    width: 75px;
    height: 75px;
    flex-shrink: 0;
    margin-top: 1em;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}