@import '~bootstrap/scss/bootstrap-utilities';

.card {
  max-width: 80vw;
  margin-top: calc(7.5vh + 100px);
  background: transparent;
  border: none;

  @include media-breakpoint-up(md) {
    max-width: 65vw;
  }

  @include media-breakpoint-up(lg) {
    max-width: 50vw;
  }

  @include media-breakpoint-up(xl) {
    max-width: 40vw;
  }

  &__title, &__subtitle {
    margin-left: calc(110px + 2rem);
  }

  &__title {
    //margin-top: -5rem;
    //margin-bottom: -1rem;
    z-index: 1;
    position: relative;
    //outline: 1px solid red;
    min-height: 95px;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    align-items: flex-end;
    text-wrap: #{"balance"};

    &:before {
      content: '';
      display: block;
      //float: left;
      position: absolute;
      background: url('../../public/assets/crow-solid.svg') no-repeat;
      background-size: contain;
      //outline: 1px solid red;
      width: 110px;
      height: 95px;
      left: -125px;
      bottom: -0.75rem;
      //top: -0.25rem;
    }
  }

  &__body {
    background: rgba($brand-white, 0.9);
    padding: 2rem 3rem 1rem;
  }

  // Variants
  &--wide {
    width: min(90vw, 80em);
    max-width: 999em;
  }
}