.big-year {
  background: #111;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  &__loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    font-family: felt-tip-roman;
    letter-spacing: 0.05em;
    z-index: 999;
  }

  &__name {
    font-size: 3em;
  }

  &__load-anim {
    font-size: 2em;
  }

  &__scroll {
    //outline: 1px solid blue;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    //margin-bottom: 250px;
    overflow: hidden;
    overflow: scroll;
  }

  &__images {
    position: absolute;
    left: 35%;
    top: 45vh;
    right: 50%;
    //transform: translateX(-50%);
    //outline: 1px solid green;
  }

  &__img {
    rotate: -5deg;
    display: inline-block;
    position: relative;
    outline: 12px solid #eee;
    box-shadow: 6px 3px 8px rgba(0, 0, 0, 0.5);
    margin-bottom: -225px;
    opacity: 0;

    &.state-visible {
      animation-name: bounce-in-fwd;
      animation-duration: 0.35s;
      animation-fill-mode: forwards;
    }

    img {
      display: block;
      height: 275px;
    }

    &:nth-child(2n) {
      rotate: 5deg;
      translate: -20px;
    }

    &:nth-child(3n) {
      rotate: -7deg;
      translate: 60px;
    }

    &:nth-child(4n) {
      rotate: 11deg;
      translate: -30px;
    }

    &:last-child {
      margin-bottom: 250px;
    }
  }

  &__img-label {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 0.75em;
    line-height: 0.95;
    text-align: right;
    color: #fff;
    text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
    font-family: felt-tip-roman;
    font-size: 1.4em;
    letter-spacing: 0.05em;
  }

  &__labels {
    color: #fff;
    position: absolute;
    top: 45vh;
    left: 50%;
    right: 10%;
    bottom: 0;
    z-index: 9925;
    //outline: 1px solid red;
    text-align: center;

  }

  &__label {
    display: block;
    font-size: 3em;
    text-shadow: 1px 2px 6px rgba(0, 0, 0, 0.5);
    font-family: felt-tip-roman;
    text-align: center;
    text-transform: uppercase;
    position: absolute;
    line-height: 0.85;
    left: 35%;
    rotate: -4deg;
    opacity: 0;
    margin-top: 200px;

    &:nth-child(2n) {
      left: -135%;
      right: 70%;
    }

    &.state-visible {
      animation-name: bounce-in-fwd;
      animation-duration: 0.35s;
      animation-fill-mode: forwards;
    }
  }

  &__audio {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50px;
    height: 50px;
    visibility: hidden;
  }

  &__count {
    color: #fff;
    font-size: 2em;
    color: #fff;
    position: absolute;
    bottom: 2.5em;
    right: 1.5em;
    font-family: felt-tip-roman;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-11-14 0:37:3
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-in-fwd
 * ----------------------------------------
 */
@keyframes bounce-in-fwd {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  /*55% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }*/
  65% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  /*81% {
    -webkit-transform: scale(0.84);
    transform: scale(0.84);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }*/
  87% {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  /*
  95% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  */
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}
