@import '~bootstrap/scss/bootstrap-utilities';

.app-header {
  background: rgba($brand-white, 0.9);
  padding: 15px 0;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 2;

  &__logo {
    width: 200px;
  }

  &__main {
    display: flex;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }
}