.play-controls {
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 10;
  bottom: 12.5px;
  left: 2rem;

  &__btn {
    width: 50px;
    height: 50px;
    background: none;
    border: none;
    border-radius: 50%;
    padding: 0;
    margin: 2.5px 3px;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      pointer-events: none;
    }

    &:active, &:hover {
      filter: brightness(0.9) contrast(1.3);
    }

    &.state-disabled {
      opacity: 0.25;
    }
  }

  &__btn--lg {
    width: 70px;
    height: 70px;
  }

  &__btn--sm {
    width: 40px;
    height: 40px;
  }
}