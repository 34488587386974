.wrap-up-block {
  margin-top: 2em;
  margin-bottom: 3em;

  &__header {
    display: flex;
    align-items: center;
  }

  &__title {
    margin-bottom: -0.2em;
  }

  &__subtitle {

  }


  &__icon {
    display: block;
    max-width: 60px;
    margin-right: 1rem;
    margin-bottom: 1.25rem;
  }

  &__body {
    margin-left: 110px;
  }
}