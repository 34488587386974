@import '~bootstrap/scss/bootstrap-utilities';

.infobox {
  position: fixed;
  top: 150px;
  left: 30px;
  max-height: calc(100vh - 155px - 80px);
  z-index: 3;
  background: rgba(255, 255, 255, 0.75);
  padding: 0.5rem 1.5rem 2rem;
  max-width: 37.5rem;
  display: flex;
  flex-direction: column;
  transition: height 0.25s ease, width 0.25s ease;

  &--inline {
    position: static;
  }

  &__title {
    margin: 0.5em 0 0.5em;
    font-size: 1.15em;
    line-height: 1.1;
    //color: $brand-dark-green;
    color: rgba($brand-red, 0.75);
    //text-transform: uppercase;
  }

  &__body {
    overflow: auto;
  }
}